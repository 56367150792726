.container {
 position: relative;
 display: inline-block;
}

.container img {
  object-fit: cover;
  object-position: center center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #000000a6;
  z-index: 999;
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color:#00000040;
}

.overlay:hover {
  opacity: 1;
}

@media (hover:hover) {
  .overlay {
    opacity: 0;
  }
}

@media (hover:none) {
  .overlay {
    opacity: 1;
  }
}