.navbar {
  width: 100%;
  padding: 10px;
  background-color: white;
  z-index: 999;
}

.link {
  text-decoration: none !important;
}

.page-content {
  max-width: 800px;
  margin: auto;
  padding: 20px 40px;
}

.navbar a + a {
  margin-left: 1rem;
}